import React, { useCallback } from "react";
import { CalendarDate } from "@internationalized/date";

import { IconButton } from "@puzzle/ui";
import { colors, styled } from "@puzzle/theme";
import { EyeOpen, EyeClosed } from "@puzzle/icons";

import Analytics, { DashboardMetricCardOpenedProperties } from "lib/analytics/analytics";

import LoadingMetrics from "./LoadingMetrics";
import { ExpandableCard } from "components/common/ExpandableCard/ExpandableCard";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { capitalize } from "@puzzle/utils";
import { BetaTag } from "../../common/BetaTag";

const Container = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",

  variants: {
    hasGraph: {
      true: {
        justifyContent: "flex-start",
      },
      false: {
        justifyContent: "space-between",
      },
    },
  },

  defaultVariants: {
    hasGraph: false,
  },
});

const Header = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  position: "relative",

  textStyle: "$headingS",
  fontWeight: "$heavy",
  color: "$gray200",

  marginBottom: "$1",

  [`${IconButton}`]: {
    position: "absolute",
    right: "-$4",
  },
});

const Footer = styled("div", {
  fontSize: "12px",
  lineHeight: "18px",
  color: colors.neutral600,
  position: "absolute",
  left: 0,
  // bit of a tight squeeze innit
  bottom: "3px",
  transform: `translateY(100%)`,
});

export const Blurrable = styled("div", {
  transition: "filter 0.1s ease-in-out",

  defaultVariants: {
    active: false,
  },

  variants: {
    active: {
      true: {
        filter: "blur(10px)",
      },
    },
  },
});

const MetricCard = ({
  loading,
  expandable = true,
  expandedContent,
  blur,
  toggleBlur: _toggleBlur,
  blurEnabled,
  header,
  lastUpdated,
  children,
  cardId,
  beta = false,
  hasGraph = false,
  isFeatureGated = false,
  shouldHandleBlur = true,
  ...props
}: React.PropsWithChildren<{
  loading: boolean;
  expandable?: boolean;
  expandButtonOnTop?: boolean;
  expandedContent?: React.ReactNode;
  lastUpdated?: CalendarDate;
  blur: boolean;
  header: string;
  toggleBlur?: () => void;
  blurEnabled: boolean;
  cardId: DashboardMetricCardOpenedProperties["card"];
  beta?: boolean;
  hasGraph?: boolean;
  isFeatureGated?: boolean;
  shouldHandleBlur?: boolean;
}>) => {
  const toggleBlur = useCallback(() => {
    _toggleBlur?.();
    Analytics.dashboardMetricCardBlurToggled({
      card: cardId,
      isBlurring: !blur,
    });
  }, [_toggleBlur, blur, cardId]);

  const monthFormatter = useCompanyDateFormatter({ month: "long", year: "numeric" });

  return (
    <ExpandableCard
      expandable={!!expandedContent && !loading && expandable && !isFeatureGated}
      expandedContent={expandedContent}
      onExpand={() => Analytics.dashboardMetricCardOpened({ card: cardId })}
      data-testid={`${capitalize(cardId)}Tile`}
      label={header}
      {...props}
    >
      {loading ? (
        <LoadingMetrics />
      ) : (
        <Container hasGraph={hasGraph}>
          <Header>
            {header}

            {beta && <BetaTag css={{ fontSize: "10px", padding: "2px 6px", marginLeft: "$0h" }} />}

            {blurEnabled && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  toggleBlur();
                }}
                data-testid="BlurButton"
                data-blurred={blur ? "true" : "false"}
                size="large"
                css={{ display: "none", "@s": { display: "block" } }}
              >
                {blur ? <EyeClosed /> : <EyeOpen />}
              </IconButton>
            )}
          </Header>
          <Blurrable active={shouldHandleBlur && blur}>{children}</Blurrable>

          {lastUpdated && <Footer>From {monthFormatter.format(lastUpdated)}</Footer>}
        </Container>
      )}
    </ExpandableCard>
  );
};

export default MetricCard;
