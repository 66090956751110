import React, { useState } from "react";

import { useAppRouter } from "lib/useAppRouter";

import links from "lib/links";

import { OnboardingStepStatus, OnboardingStep } from "graphql/types";

import { FeatureFlag } from "lib/analytics/featureFlags";
import { isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

import { useOnboardingProgress } from "./useOnboardingProgress";
import { useLaunchpadStore, LaunchpadStep } from "./launchpadStore";
import { TaskTile } from "./TaskTile";

import { rootStyle } from "./taskList.css";
import { LaunchpadTask } from "./analytics";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

export const TaskList = ({ fullSize = true }: { fullSize?: boolean }) => {
  const { onboardingProgress, updateOnboardingStep, refetchOnboardingProgress } =
    useOnboardingProgress();
  const { initialIngestCompleted } = useActiveCompany<true>();
  const { showLaunchpadOverlay, goToStep } = useLaunchpadStore();
  const {
    goToReconciliations,
    goToUsers,
    goToIntegrations,
    goToChecklist,
    goToTransactionsShortcut,
    goToPath,
  } = useAppRouter();
  const [localProgress, setLocalProgress] = useState<Record<string, OnboardingStepStatus>>({});

  const handleSkip = (step: OnboardingStep) => {
    updateOnboardingStep(step, OnboardingStepStatus.Skipped);
  };

  const handleComplete = async (
    step: OnboardingStep,
    status: OnboardingStepStatus | undefined | null
  ) => {
    if (step) {
      // Since we want to "uncheck" the step if it's completed, we need to flip the status
      const finalStepStatus =
        status === OnboardingStepStatus.Completed
          ? OnboardingStepStatus.NotCompleted
          : OnboardingStepStatus.Completed;

      // We want to immediately update progress
      setLocalProgress((prev) => ({ ...prev, [step]: finalStepStatus }));

      await updateOnboardingStep(step, finalStepStatus);
      refetchOnboardingProgress();
    }
  };

  const goToStepAndShow = (
    step: LaunchpadStep,
    status: OnboardingStepStatus | undefined | null
  ) => {
    // Don't go to the step if it's already completed
    if (status === OnboardingStepStatus.Completed) {
      return;
    }

    goToStep(step);
    showLaunchpadOverlay();
  };

  const handleTaskClick = (
    callback: () => void,
    status: OnboardingStepStatus | undefined | null
  ) => {
    if (status === OnboardingStepStatus.Completed) {
      return;
    }
    callback();
  };

  return (
    <div className={rootStyle}>
      <TaskTile
        heading="Set your bookkeeping plan"
        subheading="Unlock the full power of Puzzle by adding a bookkeeper."
        stepStatus={
          localProgress?.setBookkeepingPlan ??
          onboardingProgress?.companyOnboardingProgress?.setBookkeepingPlan
        }
        onClick={() =>
          goToStepAndShow(
            LaunchpadStep.BookKeepingPlanSelection,
            localProgress?.setBookkeepingPlan ??
              onboardingProgress?.companyOnboardingProgress?.setBookkeepingPlan
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.SetBookkeepingPlan,
            localProgress?.setBookkeepingPlan ??
              onboardingProgress?.companyOnboardingProgress?.setBookkeepingPlan
          )
        }
        analyticsTask={LaunchpadTask.SetBookkeepingPlan}
        completeOnClick={false}
        completeTooltip="Please let us know what your bookkeeping plan is."
        fullSize={fullSize}
      />
      {/* This step TBD, needs BE support if/when we add it
      <TaskTile
        heading="Set your tax plan"
        subheading="Puzzle can help you be tax ready."
        completed={false}
        stepStatus={onboardingProgress?.setBookkeepingPlan}
        onClick={() => console.log("click")}
        analyticsTask={LaunchpadTask.SetTaxPlan}
        fullSize={fullSize}
      />*/}
      <TaskTile
        heading="Verify your bank account balances"
        subheading="Ensure the foundation of your accounting is accurate."
        stepStatus={
          localProgress?.verifyBankBalances ??
          onboardingProgress?.companyOnboardingProgress?.verifyBankBalances
        }
        onClick={() =>
          handleTaskClick(
            goToReconciliations,
            localProgress?.verifyBankBalances ??
              onboardingProgress?.companyOnboardingProgress?.verifyBankBalances
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.VerifyBankBalances,
            localProgress?.verifyBankBalances ??
              onboardingProgress?.companyOnboardingProgress?.verifyBankBalances
          )
        }
        analyticsTask={LaunchpadTask.VerifyBankBalances}
        fullSize={fullSize}
        disabled={!initialIngestCompleted}
      />
      {isPosthogFeatureFlagEnabled(FeatureFlag.TransactionsEducationBanner) && (
        <TaskTile
          heading="Review & categorize your transactions"
          subheading="Learn how to navigate and categorize your transactions faster than you ever have."
          stepStatus={
            localProgress?.reviewTransactions ??
            onboardingProgress?.userOnboardingProgress?.reviewTransactions
          }
          onClick={() =>
            handleTaskClick(
              goToTransactionsShortcut,
              localProgress?.reviewTransactions ??
                onboardingProgress?.userOnboardingProgress?.reviewTransactions
            )
          }
          onComplete={() =>
            handleComplete(
              OnboardingStep.ReviewTransactions,
              localProgress?.reviewTransactions ??
                onboardingProgress?.userOnboardingProgress?.reviewTransactions
            )
          }
          analyticsTask={LaunchpadTask.RreviewCategorizeTransactions}
          fullSize={fullSize}
          disabled={!initialIngestCompleted}
        />
      )}
      <TaskTile
        heading="Review variances with Spotlight"
        subheading="Quickly detect variances in your cash activity report."
        stepStatus={
          localProgress?.reviewSpotlightVariances ??
          onboardingProgress?.userOnboardingProgress?.reviewSpotlightVariances
        }
        onClick={() =>
          handleTaskClick(
            () => goToPath("/", { query: { tour: "spotlight" } }),
            localProgress?.reviewSpotlightVariances ??
              onboardingProgress?.userOnboardingProgress?.reviewSpotlightVariances
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.ReviewSpotlightVariances,
            localProgress?.reviewSpotlightVariances ??
              onboardingProgress?.userOnboardingProgress?.reviewSpotlightVariances
          )
        }
        onSkip={() => handleSkip(OnboardingStep.ReviewSpotlightVariances)}
        analyticsTask={LaunchpadTask.ReviewVariancesWithSpotlight}
        fullSize={fullSize}
        disabled={!initialIngestCompleted}
      />
      <TaskTile
        heading="Invite your team"
        subheading="Invite your team to help you categorize, close your books, and increase visibility into your financial situation."
        stepStatus={
          localProgress?.inviteTeam ?? onboardingProgress?.companyOnboardingProgress?.inviteTeam
        }
        onClick={() =>
          handleTaskClick(
            goToUsers,
            localProgress?.inviteTeam ?? onboardingProgress?.companyOnboardingProgress?.inviteTeam
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.InviteTeam,
            onboardingProgress?.companyOnboardingProgress?.inviteTeam
          )
        }
        onSkip={() => handleSkip(OnboardingStep.InviteTeam)}
        analyticsTask={LaunchpadTask.InviteYourTeam}
        fullSize={fullSize}
      />
      <TaskTile
        heading="Review your monthly close checklist"
        subheading="Close your books with ease."
        stepStatus={
          localProgress?.reviewMonthEndChecklist ??
          onboardingProgress?.userOnboardingProgress?.reviewMonthEndChecklist
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.ReviewMonthEndChecklist,
            localProgress?.reviewMonthEndChecklist ??
              onboardingProgress?.userOnboardingProgress?.reviewMonthEndChecklist
          )
        }
        onClick={() =>
          handleTaskClick(
            goToChecklist,
            localProgress?.reviewMonthEndChecklist ??
              onboardingProgress?.userOnboardingProgress?.reviewMonthEndChecklist
          )
        }
        analyticsTask={LaunchpadTask.ReviewMonthlyCloseChecklist}
        fullSize={fullSize}
        disabled={!initialIngestCompleted}
      />
      <TaskTile
        heading="Connect all of your financial accounts"
        subheading="Increase the accuracy of your data by connection all of your accounts."
        stepStatus={
          localProgress?.connectFinancialAccounts ??
          onboardingProgress?.userOnboardingProgress?.connectFinancialAccounts
        }
        onClick={() =>
          handleTaskClick(
            goToIntegrations,
            localProgress?.connectFinancialAccounts ??
              onboardingProgress?.userOnboardingProgress?.connectFinancialAccounts
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.ConnectFinancialAccounts,
            localProgress?.connectFinancialAccounts ??
              onboardingProgress?.userOnboardingProgress?.connectFinancialAccounts
          )
        }
        onSkip={() => handleSkip(OnboardingStep.ConnectFinancialAccounts)}
        analyticsTask={LaunchpadTask.ConnectFinancialAccounts}
        fullSize={fullSize}
      />
      <TaskTile
        heading="Get puzzle certified"
        subheading="Become a certified Puzzle pro."
        stepStatus={
          localProgress?.getPuzzleCertified ??
          onboardingProgress?.userOnboardingProgress?.getPuzzleCertified
        }
        onClick={() =>
          handleTaskClick(
            () => window.open(links.puzzleCerfication, "_blank")?.focus(),
            localProgress?.getPuzzleCertified ??
              onboardingProgress?.userOnboardingProgress?.getPuzzleCertified
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.GetPuzzleCertified,
            localProgress?.getPuzzleCertified ??
              onboardingProgress?.userOnboardingProgress?.getPuzzleCertified
          )
        }
        onSkip={() => handleSkip(OnboardingStep.GetPuzzleCertified)}
        analyticsTask={LaunchpadTask.GetPuzzleCertified}
        fullSize={fullSize}
      />
      <TaskTile
        heading="Configure your advanced accounting settings"
        subheading="Configure sublegers, automations and other advanced settings."
        stepStatus={
          localProgress?.configureAccountSettings ??
          onboardingProgress?.companyOnboardingProgress?.configureAccountSettings
        }
        onClick={() =>
          goToStepAndShow(
            LaunchpadStep.AdvancedAccountingSettings,
            localProgress?.configureAccountSettings ??
              onboardingProgress?.companyOnboardingProgress?.configureAccountSettings
          )
        }
        onComplete={() =>
          handleComplete(
            OnboardingStep.ConfigureAccountSettings,
            localProgress?.configureAccountSettings ??
              onboardingProgress?.companyOnboardingProgress?.configureAccountSettings
          )
        }
        onSkip={() => handleSkip(OnboardingStep.ConfigureAccountSettings)}
        analyticsTask={LaunchpadTask.ConfigureYourAccountingSettings}
        fullSize={fullSize}
      />
    </div>
  );
};
