import { ChevronUp } from "@puzzle/icons";
import { CalendarDateString, parseDate } from "@puzzle/utils";
import { styled } from "@puzzle/theme";
import { Collapse, Loader } from "ve";
import { useExpensesExceededFeatureGate } from "components/featureGate/useExpensesExceededFeatureGate";
import { MonthlyExpensesExceededBanner } from "components/featureGate/MonthlyExpensesExceededBanner";
import Analytics from "lib/analytics/analytics";
import { Route } from "lib/routes";
import mix from "polished/lib/color/mix";
import React, { useMemo } from "react";
import { useLocalStorage } from "react-use";
import { useIntercom } from "react-use-intercom";
import { AxisDomain } from "recharts/types/util/types";
import { CashChart } from "./CashChart";
import { SimpleBarChart } from "./SimpleBarChart";
import { Charts } from "graphql/types";
import { CollapseButton } from "./CollapseButton";
import { Text } from "@puzzle/ui";
import { colors } from "@puzzle/theme";

type ChartDataType = {
  __typename?: "ChartDatePoint";
  date: CalendarDateString;
  values: { __typename?: "ChartPoint"; key: string; value: string }[];
}[];

const Root = styled("div", {
  background: colors.rhino900,
  border: `1px solid ${colors.neutral700}`,
  borderRadius: "$1",
  position: "relative",
});

const Content = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 863,
  "@m": {
    height: 826,
  },
  "@l": {
    height: 300,
  },
});

const ChartGrid = styled("div", {
  display: "grid",
  width: "100%",
  height: 863,
  gridTemplateAreas: `
  'main'
  'mini-a'
  'mini-b'
  `,
  "@m": {
    height: 826,
  },
  "@l": {
    height: 300,
    gridTemplate: "repeat(2, 1fr) / 60% 40%",
    gridTemplateAreas: `
    'main mini-a'
    'main mini-b'
    `,
  },
});

const Error = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1h",
  maxWidth: 390,
  margin: "$5 auto",
  fontSize: "$bodyS",
  lineHeight: "$bodyXS",
  textAlign: "center",

  color: "$gray400",

  p: {
    margin: 0,
  },

  a: {
    color: "inherit",
    textDecoration: "underline",
  },
});

const ContactLink = ({ id }: { id: string }) => {
  const intercom = useIntercom();
  return (
    <a
      href="javascript:void(0)"
      onClick={() => {
        Analytics.dashboardChartHelpClicked({ id });
        intercom.showNewMessage();
      }}
    >
      Contact Puzzle
    </a>
  );
};

export const ChartTile = ({ charts, loading }: { charts?: Charts; loading?: boolean }) => {
  const { expenseExceededDate } = useExpensesExceededFeatureGate();
  const [collapsed, setCollapsed] = useLocalStorage("dashboard-charts-collapsed", false);

  // Filter out data that is after the expense exceeded date, if one is present.
  const { isFullyDateGated, cashBalance, revenue, spending } = useMemo(() => {
    if (expenseExceededDate && charts) {
      const cashBalance: ChartDataType = [];
      (charts.cashBalance || []).forEach((value) => {
        if (parseDate(value.date) < expenseExceededDate) {
          cashBalance.push(value);
        }
      });

      const revenue: ChartDataType = [];
      (charts.revenue || []).forEach((value) => {
        if (parseDate(value.date) < expenseExceededDate) {
          revenue.push(value);
        }
      });

      const spending: ChartDataType = [];
      (charts.spending || []).forEach((value) => {
        if (parseDate(value.date) < expenseExceededDate) {
          spending.push(value);
        }
      });

      const isFullyDateGated =
        cashBalance.length === 0 && revenue.length === 0 && spending.length === 0;

      return {
        isFullyDateGated,
        cashBalance,
        revenue,
        spending,
      };
    } else {
      return {
        cashBalance: charts?.cashBalance || [],
        revenue: charts?.revenue || [],
        spending: charts?.spending || [],
        isFullyDateGated: false,
      };
    }
  }, [charts, expenseExceededDate]);

  const spendingRevenueDomain = useMemo<AxisDomain>(() => {
    let min = 0;
    let max = 0;
    if (!charts) {
      return [min, max];
    }

    [...revenue, ...spending].forEach(({ values }) => {
      const value = parseFloat(values[0].value);

      if (value > max) {
        max = value;
      }
      if (value < min) {
        min = value;
      }
    });

    return [min, max];
  }, [charts, revenue, spending]);

  const hasRevenue = useMemo(() => {
    if (!charts) {
      return false;
    }
    return revenue.some((item) => item.values.every((x) => x.value !== "0"));
  }, [revenue]);

  const content = useMemo(() => {
    if (loading) {
      return <Loader size={48} />;
    }

    if (charts) {
      return (
        <ChartGrid>
          <CashChart data={cashBalance || []} css={{ gridArea: "main" }} />
          <SimpleBarChart
            href={Route.spending}
            domain={spendingRevenueDomain}
            dataKey="spending"
            title="Spending"
            data={spending || []}
            colors={{
              active: colors.blue400V2,
              inactive: mix(0.4, colors.rhino900, colors.blue400V2),
            }}
            css={{ gridArea: "mini-a", borderBottom: `1px solid ${colors.neutral700}` }}
          />
          <SimpleBarChart
            href={Route.revenue}
            domain={spendingRevenueDomain}
            dataKey="revenue"
            title="Revenue"
            data={revenue || []}
            colors={{
              active: "#77D9FF",
              inactive: mix(0.4, colors.rhino900, "#77D9FF"),
            }}
            css={{ gridArea: "mini-b" }}
            message={
              hasRevenue ? undefined : (
                <>
                  No revenue from the past 6 months.
                  <br />
                  Is this wrong? <ContactLink id="revenue" />
                </>
              )
            }
          />
        </ChartGrid>
      );
    }

    return (
      <Error>
        <Text variant="bodyM" color="gray300" css={{ fontWeight: "$heavy !important" }}>
          Not enough data to display charts
        </Text>
        <p>
          Once you have more than one month of data, you can compare your cash, spending, and
          revenue here.
        </p>

        <p>
          Is this wrong? <ContactLink id="no-data" />
        </p>
      </Error>
    );
  }, [charts, hasRevenue, loading, spendingRevenueDomain, cashBalance, revenue, spending]);

  return (
    <Root>
      <CollapseButton
        onClick={() =>
          setCollapsed(() => {
            Analytics.dashboardChartsToggled({ isClosing: !collapsed });
            return !collapsed;
          })
        }
      >
        Charts
        <ChevronUp rotate={collapsed ? 180 : 0} />
      </CollapseButton>
      <Collapse open={!collapsed} style={{ width: "100%" }}>
        <Content>
          {isFullyDateGated ? (
            <MonthlyExpensesExceededBanner subtext="view financials for the selected period" />
          ) : (
            content
          )}
        </Content>
      </Collapse>
    </Root>
  );
};
