import React, { useCallback, useEffect, useRef, useMemo } from "react";
import dynamic from "next/dynamic";
import { Tabs, GroupBy } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { DashboardReportViewedProperties } from "lib/analytics/ampli/index";
import Analytics from "lib/analytics/analytics";
import { DynamicReportType, LedgerView } from "graphql/types";
import { useStickyReportContext, TABS } from "components/reports/StickyReportContext";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useReportContext } from "components/reports/ReportContext";
import { SpotlightFeatureGateModal } from "components/featureGate/SpotlightFeatureGateModal";
import { useFeatureGateStore } from "components/featureGate/featureGateStore";
import { useDashboardReportAnalyticsStore } from "components/dashboard/Dashboard/DashboardReportAnalyticsStore";
import { useSpotlightTourContext } from "components/dashboard/Dashboard/SpotlightTour/SpotlightTourContext";
import { colors, S } from "@puzzle/theme";
import { LoadingReportStatic } from "./LoadingReportStatic";
import { BreakoutProvider } from "./BreakoutProvider";
import { HighlightProvider } from "./HighlightProvider";
import { DeltaProvider, useDelta } from "./Filters/DeltaProvider";
import LoadingReport from "./LoadingReport";
import ReportContent from "./ReportContent";
import IngestingReport from "./IngestingReport";
import ReportContentFilters from "./ReportContentFilters";
import { ReportLevel } from "./ReportLevel";
import { Syncing } from "@puzzle/icons";
import { Button } from "ve";
import { useFetchLedgerReport } from "./useFetchLedgerReport";
import {
  shouldShowLoadingUI,
  shouldShowSyncingUI,
} from "components/dashboard/utils/fetchLedgerReport/statusGroups";
import { DATA_TEST_IDS } from "./constants";
import { LedgerReportStatus } from "components/dashboard/utils/fetchLedgerReport/types";

const DynamicSpotlightTour = dynamic(() =>
  import("components/dashboard/Dashboard/SpotlightTour/SpotlightTour").then(
    (mod) => mod.SpotlightTour
  )
);

const Wrapper = styled("div", {
  border: `1px solid ${colors.neutral800}`,
  borderRadius: "$1",
  overflow: "hidden",

  "@media print": {
    border: "none",
  },
});

const SyncingStatus = ({
  text,
  isSyncing,
  onRefresh,
}: {
  text: string;
  isSyncing: boolean;
  onRefresh?: () => void;
}) => {
  const handleClick = () => {
    onRefresh && onRefresh();
  };

  return (
    <div style={{ display: "flex", marginTop: S[2] }}>
      <Button
        data-testid={DATA_TEST_IDS.SYNC_STATUS_REFRESH_BUTTON}
        icon
        variant="secondary"
        size="miniSquare"
        shape="buttonEvenMoreSquare"
        onClick={() => handleClick()}
        disabled={isSyncing}
        css={{ backgroundColor: "transparent" }}
        aria-label={isSyncing ? "Syncing your data" : "Refresh report"}
      >
        <Syncing size={14} style={{ marginTop: "3px" }} spinning={isSyncing} />
      </Button>
      <p
        data-testid={DATA_TEST_IDS.SYNC_STATUS_TEXT}
        style={{
          marginLeft: S["1h"],
          marginTop: "5px",
          marginBottom: "0",
          color: colors.gray300,
        }}
      >
        {text}
      </p>
    </div>
  );
};

const Reports = () => {
  const { company, initialIngestCompleted } = useActiveCompany<true>();

  const {
    timePeriods,
    activeGroupBy, // reportPeriodGrouping
    stickyOptions: {
      activeTab, // reportType
      detailLevel, // reportLevel
      end, // endDate
      filter,
      preset: { key }, // dateRangePreset
      start, // startDate
      view, // basis
    },
    setStickyOptions,
  } = useStickyReportContext();

  const {
    deltaOptions: { enabled: isSpotlightOn },
  } = useDelta();

  // A custom store for tracking the timing of events.
  const { pageRequestedAt, dataReceivedAt, tableRenderedAt } = useDashboardReportAnalyticsStore();

  // This is so we only send the payload once per page load.
  const hasSentPayloadOnPageLoad = useRef(false);

  // Send the analytics payload when the table is rendered.
  useEffect(() => {
    if (tableRenderedAt > 0 && !hasSentPayloadOnPageLoad.current) {
      const dashboardReportViewedPayload: DashboardReportViewedProperties = {
        basis: view,
        dateRangePreset: key,
        endDate: end,
        highlightModeEnabled: isSpotlightOn,
        reportLevel: detailLevel,
        reportPeriodGrouping: activeGroupBy as GroupBy,
        reportType: activeTab,
        startDate: start,
        msBetweenPageRequestedAndDataReceived: dataReceivedAt - pageRequestedAt,
        msBetweenDataReceivedAndTableRendered: tableRenderedAt - dataReceivedAt,
      };

      // console.log("dashboardReportViewedPayload", dashboardReportViewedPayload); // uncomment to debug
      Analytics.dashboardReportViewed(dashboardReportViewedPayload);
      hasSentPayloadOnPageLoad.current = true;
    }
  }, [pageRequestedAt, dataReceivedAt, tableRenderedAt]);

  const { classificationsData } = useReportContext();
  const tab = useMemo(() => TABS.find((t) => t.value === activeTab) ?? TABS[0], [activeTab]);
  const type = tab.value as DynamicReportType;
  const isCashAcrrualViewSettingOn = isPosthogFeatureFlagEnabled(
    FeatureFlag.CashAcrrualReportDefaultFilter
  );

  const enableClassesAndDepts =
    (isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1) ?? false) &&
    type === DynamicReportType.ProfitAndLoss;

  const setTabValue = useCallback(
    (value: string) => {
      Analytics.dashboardReportTabChanged({ reportType: value as DynamicReportType });
      setStickyOptions({ activeTab: value as DynamicReportType });
      if (!isCashAcrrualViewSettingOn && value === DynamicReportType.CashActivityReport) {
        setStickyOptions({ view: LedgerView.Cash });
      }
    },
    [isCashAcrrualViewSettingOn, setStickyOptions]
  );

  // Get report on component mount
  const report = useFetchLedgerReport({
    companyId: company.id,
    initialIngestCompleted,
    timePeriods,
    type,
    view:
      isCashAcrrualViewSettingOn && activeTab === DynamicReportType.CashActivityReport
        ? LedgerView.Cash
        : view,
    groupBy: activeGroupBy,
    classifications: classificationsData,
    filter,
  });

  // Manually refresh the report
  const handleRefresh = () => {
    report.refetch();
  };

  const content = useMemo(() => {
    if (!initialIngestCompleted) {
      return <IngestingReport />;
    }

    if (shouldShowLoadingUI(report.status)) {
      return (
        <>
          <SyncingStatus isSyncing={true} text={report.status} onRefresh={handleRefresh} />
          <ReportContentFilters
            reportType={type}
            enableClassesAndDepts={enableClassesAndDepts}
            tab={tab}
          />
          {isPosthogFeatureFlagEnabled(FeatureFlag.DashboardFsReportsWithStatus) ? (
            <LoadingReportStatic />
          ) : (
            <LoadingReport />
          )}
        </>
      );
    }

    const formattedText = () => {
      if (report.status === LedgerReportStatus.ALL_DATA_FETCHED_AND_CACHED && report.timestamp) {
        return `Up to date as of ${new Date(report.timestamp).toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })}`;
      }
      return report.status;
    };

    return (
      <>
        <SyncingStatus
          isSyncing={shouldShowSyncingUI(report.status)}
          text={formattedText()}
          onRefresh={handleRefresh}
        />
        <ReportContentFilters
          reportType={type}
          enableClassesAndDepts={enableClassesAndDepts}
          tab={tab}
        />
        <ReportContent report={report} reportType={type} />
      </>
    );
  }, [report, type, initialIngestCompleted, enableClassesAndDepts, tab, handleRefresh]);

  // We need the report data outside of the tab layout.
  // We need the high watermark token for the CSV download to make sure it's the same data.
  // We essentially always return the same thing, but Apollo will still cache.
  const reportSection = useMemo(() => {
    return TABS.map((tab) => ({
      ...tab,
      content,
    }));
  }, [report, type, initialIngestCompleted, enableClassesAndDepts]);

  const tabbedReports = useMemo(() => {
    return (
      <>
        <Tabs
          variant="default"
          items={reportSection}
          value={activeTab}
          onValueChange={setTabValue}
          css={{ padding: "$1 $3 0 $3", backgroundColor: colors.rhino900 }}
          data-testid={DATA_TEST_IDS.REPORT_TABS}
          actions={<ReportLevel />}
        />
      </>
    );
  }, [reportSection, activeTab, setTabValue]);

  return (
    <HighlightProvider report={report}>
      <BreakoutProvider reportType={type}>{tabbedReports}</BreakoutProvider>
    </HighlightProvider>
  );
};

export const ReportsContainerWithStatus = ({ className }: { className?: string }) => {
  const { isInProgress } = useSpotlightTourContext();
  const isSpotlightFeatureGateModalShown = useFeatureGateStore(
    (state) => state.isSpotlightFeatureGateModalShown
  );
  const hideSpotlightFeatureGateModal = useFeatureGateStore(
    (state) => state.hideSpotlightFeatureGateModal
  );

  return (
    <Wrapper className={className}>
      <DeltaProvider>
        <Reports />
      </DeltaProvider>

      <SpotlightFeatureGateModal
        open={isSpotlightFeatureGateModalShown}
        onClose={() => {
          hideSpotlightFeatureGateModal();
        }}
      />
      {isInProgress && <DynamicSpotlightTour />}
    </Wrapper>
  );
};
