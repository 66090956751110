/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Input,
  NumberInput,
  Popover,
  PopoverProps,
  useDialogReset,
  DateInput,
  GroupBy,
} from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { useDelta } from "./DeltaProvider";
import { useSetState } from "react-use";
import { toCalendarMonthString, parseDate } from "@puzzle/utils";
import { ReportTimePeriod } from "graphql/types";
import { Box } from "@puzzle/ve";
import { S, colors } from "@puzzle/theme";
import { DATA_TEST_IDS } from "../constants";

const Section = styled("div", {
  padding: "$1h $2",

  "&:first-child": {
    borderBottom: `1px solid ${colors.black200}`,
  },
});

const Body = styled(Popover.Body, {
  padding: 0,
  maxWidth: "288px",
  textVariant: "$bodyS",
});

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",

  [`${Button}`]: {
    padding: "$1h $2",
    height: "auto",
    margin: 0,
    lineHeight: 1,
    width: 80,

    "&:first-child": {
      borderColor: "$gray600",
    },
  },

  [`${Input}`]: {
    borderColor: "$gray600",
  },
});

const MutedText = styled("div", {
  color: "$gray400",
});

const LabelText = styled("div", {
  color: "$gray300",
});

const Symbol = styled(LabelText, {
  textVariant: "$bodyS",
  fontWeight: "$bold",
});

const ValueRow = styled("div", {
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
});

const ComparisonTool = (props: PopoverProps) => {
  const {
    deltaOptions,
    setDeltaOptions,
    selectableFirstTimePeriods,
    firstTimePeriod,
    setFirstTimePeriod,
    lastTimePeriod,
    groupBy,
  } = useDelta();

  const stateFromOptions = useMemo(
    () => ({
      percentValue: deltaOptions.atLeastPercent * 100,
      dollarValue: deltaOptions.atLeastDollarAmount,
      firstTimePeriod: firstTimePeriod,
    }),
    [deltaOptions.atLeastDollarAmount, deltaOptions.atLeastPercent, firstTimePeriod]
  );

  const [internalState, setInternalState] = useSetState<{
    percentValue: number;
    dollarValue: number;
    firstTimePeriod: ReportTimePeriod;
  }>(stateFromOptions);

  const onSave: React.FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      setDeltaOptions({
        atLeastDollarAmount: internalState.dollarValue,
        atLeastPercent: internalState.percentValue / 100,
      });
      setFirstTimePeriod(internalState.firstTimePeriod);
      props.onOpenChange?.(false);
    },
    [
      internalState.dollarValue,
      internalState.percentValue,
      internalState.firstTimePeriod,
      props,
      setDeltaOptions,
      setFirstTimePeriod,
    ]
  );

  useEffect(() => {
    setInternalState(stateFromOptions);
  }, [setInternalState, stateFromOptions]);

  useDialogReset(props.open, () => {
    setInternalState(stateFromOptions);
  });

  return (
    <Popover {...props} data-testid={DATA_TEST_IDS.SPOTLIGHT_SIGNIFICANT_VALUES}>
      <Popover.Title>{`Spotlight significant values`}</Popover.Title>
      <Body>
        <Form onSubmit={onSave}>
          <Section>
            <LabelText
              css={{
                marginBottom: "$2",
              }}
            >
              Spotlight the changes that are:
            </LabelText>

            <Box
              css={{
                display: "grid",
                gridAutoFlow: "row",
                gap: S["0h"],
                paddingBottom: S["0h"],
              }}
            >
              <ValueRow>
                <LabelText>Greater than</LabelText>
                <NumberInput
                  size="small"
                  value={internalState.percentValue}
                  css={{ width: 60 }}
                  decimalScale={2}
                  onValueChange={({ floatValue }) => {
                    setInternalState({ percentValue: floatValue });
                  }}
                  autoFocus
                />
                <Symbol>%</Symbol>
              </ValueRow>

              <div>AND</div>

              <ValueRow>
                <LabelText>Greater than</LabelText>
                <Symbol>$</Symbol>
                {/* This doesn't use CurrencyInput because of its annoying inactive state */}
                <NumberInput
                  thousandSeparator={true}
                  decimalScale={2}
                  size="small"
                  value={internalState.dollarValue}
                  css={{ width: 100 }}
                  onValueChange={({ floatValue }) => {
                    setInternalState({ dollarValue: floatValue });
                  }}
                />
              </ValueRow>
            </Box>
          </Section>

          <Section>
            {groupBy !== GroupBy.Total && (
              <>
                <Box css={{ marginBottom: S["2"] }}>
                  <MutedText>Compare</MutedText>
                </Box>

                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DateInput
                    placeholder="a"
                    iconSide="right"
                    inputReadOnly
                    minDate={
                      selectableFirstTimePeriods[0] &&
                      parseDate(selectableFirstTimePeriods[0].start)
                    }
                    maxDate={parseDate(lastTimePeriod.start).subtract({ months: 1 })}
                    value={parseDate(internalState.firstTimePeriod.start)}
                    onChange={(value) => {
                      if (value) {
                        const firstTimePeriod = selectableFirstTimePeriods.find(
                          (period) =>
                            toCalendarMonthString(parseDate(period.start)) ===
                            toCalendarMonthString(value)
                        );
                        if (firstTimePeriod) {
                          setInternalState({ firstTimePeriod });
                        }
                      }
                    }}
                    view={groupBy}
                    css={{ width: 128, whiteSpace: "nowrap" }}
                  >
                    <div>{internalState.firstTimePeriod.timePeriodKey}</div>
                  </DateInput>

                  <Box css={{ marginLeft: S["1"], marginRight: S["1"] }}>TO</Box>

                  <div>{lastTimePeriod.timePeriodKey}</div>
                </Box>
              </>
            )}

            <Box
              css={{
                marginTop: S["3"],
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                justifySelf: "flex-end",
              }}
            >
              <Button variant="secondary" onClick={() => props.onOpenChange?.(false)}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Box>
          </Section>
        </Form>
      </Body>
    </Popover>
  );
};

export default ComparisonTool;
