import React, { useCallback, useMemo, useState } from "react";
import {
  Bar,
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Cell,
  Tooltip as ChartTooltip,
  Line,
} from "recharts";
import { Button, Tooltip } from "@puzzle/ui";
import { styled, CSS, S, colors, vars } from "@puzzle/theme";
import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { formatMoney, parseDate, today, useLocalDateFormatter } from "@puzzle/utils";
import { ChevronRight } from "@puzzle/icons";

import { ChartDatePointFragment } from "./graphql.generated";
import { AxisDomain } from "recharts/types/util/types";
import { createTrend } from "./createTrend";
import { BarIcon } from "./icons/BarIcon";
import { useDiagonalMask } from "./shared";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";
import Analytics from "lib/analytics/analytics";
import { Link } from "components/common/Link";

// TODO There's some repetition between this and CashChart

const Title = styled("div", {
  display: "flex",
  width: "fit-content",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$1",

  textVariant: "$headingS",
  fontWeight: "$heavy",
  color: "$gray200",

  transform: "translateY(-3px)",
});

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  height: "100%",
  padding: "$2 $1h 0",
});

const Tick = styled("text", {
  fill: "$gray400",
  fontSize: "$bodyXS",
  textAnchor: "middle",
});

const LegendItem = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
  lineHeight: 0,
});

const TooltipLegendItem = styled(LegendItem, {
  display: "grid",
  gridTemplateColumns: "16px 1fr",
  color: "$gray200",
  "*:first-child": {
    justifySelf: "center",
  },
});

const Message = styled("div", {
  fontSize: "$bodyS",
  lineHeight: "$bodyL",
  color: "$gray400",
  textAlign: "center",
  margin: "auto 0",

  a: {
    color: "$gray200",
  },
});

type Key = "spending" | "revenue";
const KeyToLabel: Record<Key, string> = {
  spending: "Spending",
  revenue: "Revenue",
};

/**
 * Renders a simple bar chart with a line-fit.
 *
 * This currently makes all values absolute.
 * For some reason, revenue is negative and spending is positive.
 */
export const SimpleBarChart = ({
  data: _data,
  width,
  height,
  title,
  href,
  colors: barColors,
  dataKey,
  css,
  domain,
  message,
}: {
  data: ChartDatePointFragment[];
  width?: number | string;
  height?: number | string;
  colors: {
    active: string;
    inactive: string;
  };
  title: string;
  dataKey: Key;
  css?: CSS;
  domain: AxisDomain;
  href?: string;
  message?: React.ReactNode;
}) => {
  const { timeZone } = useActiveCompany<true>();
  const icon = <BarIcon color={barColors.active} />;
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const shortMonthFormatter = useLocalDateFormatter({
    month: "short",
  });
  const monthFormatter = useLocalDateFormatter({
    month: "long",
    year: "numeric",
  });
  const { maskUrl, mask } = useDiagonalMask();

  const handleMouseEvent = useCallback<CategoricalChartFunc>(
    (state, event) => {
      if (state.isTooltipActive && typeof state.activeTooltipIndex === "number") {
        setActiveIndex(state.activeTooltipIndex);
      } else {
        setActiveIndex(null);
      }

      if (event.type === "mouseenter") {
        Analytics.dashboardChartHovered({ id: dataKey });
      }
    },
    [dataKey]
  );

  // Yes this could be more efficient
  const data = useMemo(() => {
    const trend = createTrend(
      _data,
      (item, index) => index,
      (item) => parseFloat(item.values.find((x) => x.key === dataKey)!.value)
    );

    return _data.map((item, index) => ({
      date: item.date,
      value: parseFloat(item.values.find((x) => x.key === dataKey)!.value),
      trend: trend.calcY(index),
    }));
  }, [_data, dataKey]);

  return (
    <Root css={css}>
      <Title>
        {title}

        {href && (
          <Tooltip content={`See more in ${KeyToLabel[dataKey]}`} arrow={false}>
            <Link href={href}>
              <Button
                onClick={() => Analytics.dashboardChartLinkClicked({ id: dataKey })}
                variant="secondary"
                size="mini"
                css={{
                  padding: "$0h !important",
                  "*": {
                    lineHeight: 0,
                  },
                }}
              >
                <ChevronRight size={14} />
              </Button>
            </Link>
          </Tooltip>
        )}
      </Title>

      {message ? (
        <Message>{message}</Message>
      ) : (
        <ResponsiveContainer width={width} height={height}>
          <ComposedChart
            data={data}
            onMouseEnter={handleMouseEvent}
            onMouseLeave={handleMouseEvent}
            onMouseMove={handleMouseEvent}
            margin={{
              left: 16,
              right: 16,
              top: 0,
              bottom: 0,
            }}
          >
            {mask}

            <ChartTooltip
              wrapperStyle={{ outline: "none" }}
              cursor={false}
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <Box
                      css={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: colors.black,
                        borderRadius: vars.radii[1],
                        borderColor: colors.black,
                      }}
                    >
                      <Box
                        css={{
                          borderBottom: `px solid ${colors.gray700}`,
                          padding: `${S["0h"]} ${S["2"]}`,
                        }}
                      >
                        <Text variant="bodyXS" color="gray400">
                          {monthFormatter.format(parseDate(payload[0].payload.date))}
                        </Text>
                      </Box>
                      <Box
                        css={{
                          display: "grid",
                          gridAutoFlow: "row",
                          gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
                          flexDirection: "column",
                          padding: S["2"],
                          gap: S["1"],
                        }}
                      >
                        {payload.map(
                          ({ name, value }) =>
                            name !== "trend" && (
                              <React.Fragment key={name}>
                                <TooltipLegendItem>
                                  {icon}
                                  {/* empty is fine */}
                                  {KeyToLabel[dataKey as Key] || "Value"}
                                </TooltipLegendItem>
                                <Text style={{ textAlign: "right", color: "$white" }}>
                                  {formatMoney(
                                    { amount: value as number },
                                    { truncateValue: true }
                                  )}
                                </Text>
                              </React.Fragment>
                            )
                        )}
                      </Box>
                    </Box>
                  );
                }

                return null;
              }}
            />

            <XAxis
              scale="point"
              padding={{ left: 0, right: 0 }}
              type="category"
              interval={0}
              dataKey="date"
              dy={10}
              axisLine={false}
              tickLine={false}
              tick={({ payload, ...props }) => {
                return (
                  <Tick {...props}>{shortMonthFormatter.format(parseDate(payload.value))}</Tick>
                );
              }}
            />

            <YAxis
              hide
              tickLine={false}
              axisLine={false}
              domain={domain}
              padding={{ top: 0, bottom: 0 }}
            />
            <Bar
              minPointSize={2}
              isAnimationActive={false}
              fill={barColors.inactive}
              maxBarSize={20}
              radius={[4, 4, 0, 0]}
              // TODO make a type-safe flattening util, or getter factory
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  mask={parseDate(entry.date).compare(today(timeZone)) > 0 ? maskUrl : undefined}
                  key={`cell-${index}`}
                  fill={
                    entry.value === 0
                      ? "none"
                      : barColors[
                          activeIndex === index || activeIndex === null ? "active" : "inactive"
                        ]
                  }
                />
              ))}
            </Bar>

            <Line
              isAnimationActive={false}
              dataKey="trend"
              stroke={colors.gray200}
              strokeDasharray="4 8 4 8"
              strokeWidth={2}
              dot={false}
              activeDot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Root>
  );
};
