import React from "react";
import { colors, styled } from "@puzzle/theme";
import { S } from "@puzzle/theme";

const MARGINS = {
  CHILD: S[3],
  GRANDCHILD: S[6],
  GREAT_GRANDCHILD: S[9],
};
const ROW_HEIGHT = S[3];

// Report container
const ReportWrapper = styled("div", {
  position: "relative",
  paddingTop: "$1",
  paddingBottom: "$3",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

// Section for grouping child rows
const Section = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  width: "100%",
});

// Base row skeleton element with shared styling
const SkeletonElement = styled("div", {
  borderRadius: "$3",
});

const TopRow = styled(SkeletonElement, {
  height: ROW_HEIGHT,
  display: "flex",
  position: "relative",
  width: "66%",
  backgroundColor: "transparent",
  marginLeft: "auto", // right align
  gap: "$2",
  justifyContent: "space-between", // Distribute columns evenly, no matter how many we have
});

// Column for TopRow
const TopRowColumn = styled(SkeletonElement, {
  height: "100%",
  flex: 1,
  backgroundColor: colors.white50,
});

// Row skeleton with hierarchy levels
const Row = styled(SkeletonElement, {
  height: ROW_HEIGHT,
  display: "flex",
  alignItems: "center",
  position: "relative",
  variants: {
    level: {
      parent: {
        marginLeft: "0px",
        width: "100%", // Full width for parent
        backgroundColor: "$white",
        opacity: 0.085,
      },
      child: {
        marginLeft: MARGINS.CHILD,
        width: `calc(100% - ${MARGINS.CHILD})`, // Adjust width to align right side
        backgroundColor: "$white",
        opacity: 0.065,
      },
      grandchild: {
        marginLeft: MARGINS.GRANDCHILD,
        width: `calc(100% - ${MARGINS.GRANDCHILD})`, // Adjust width to align right side
        backgroundColor: "$white",
        opacity: 0.05,
      },
      "great-grandchild": {
        marginLeft: MARGINS.GREAT_GRANDCHILD,
        width: `calc(100% - ${MARGINS.GREAT_GRANDCHILD})`, // Adjust width to align right side
        backgroundColor: "$white",
        opacity: 0.04,
      },
    },
  },
});

export const LoadingReportStatic = () => {
  return (
    <ReportWrapper>
      <Section>
        <TopRow>
          <TopRowColumn />
          <TopRowColumn />
          <TopRowColumn />
          <TopRowColumn />
          <TopRowColumn />
        </TopRow>
      </Section>

      <Section>
        <Row level="parent" />
        <Row level="child" />
        <Row level="grandchild" />
        <Row level="great-grandchild" />
        <Row level="great-grandchild" />
        <Row level="grandchild" />
        <Row level="child" />
        <Row level="grandchild" />
        <Row level="grandchild" />
      </Section>

      <Section>
        <Row level="parent" />
        <Row level="child" />
        <Row level="grandchild" />
        <Row level="great-grandchild" />
        <Row level="child" />
      </Section>

      <Section>
        <Row level="parent" />
        <Row level="child" />
        <Row level="child" />
        <Row level="child" />
        <Row level="grandchild" />
        <Row level="grandchild" />
      </Section>

      <Section>
        <Row level="parent" />
        <Row level="parent" />
        <Row level="child" />
        <Row level="grandchild" />
      </Section>
    </ReportWrapper>
  );
};
